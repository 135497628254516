import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ContactForm.css';
import ReactConfetti from 'react-confetti';

const ContactForm = ({ onClose, onSubmit, sessionId }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    topic: ''
  });
  const [error, setError] = useState(null);

  const [submissionStatus, setSubmissionStatus] = useState(null); // 'success' or 'error'
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    if (showConfetti) {
      const timer = setTimeout(() => setShowConfetti(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [showConfetti]);

  useEffect(() => {
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      topic: ''
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: name === 'mobile' ? formatPhoneNumber(value) : value
    }));
  };

  const formatPhoneNumber = (value) => {
    const phoneNumber = value.replace(/\D/g, '');
    const match = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return value;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        '/api/contact',
        { ...formData, sessionId },
        { withCredentials: true }
      );
      console.log('Form submission response:', response);
      setSubmissionStatus('success');
      setShowConfetti(true);
      onSubmit(formData);
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        topic: ''
      }); // Reset form fields
    } catch (error) {
      console.error('Error submitting form:', error.response || error);
      setSubmissionStatus('error');
      const errorMessage = error.response?.data?.message || 'An error occurred. Please try again.';
      setError(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="contact-form-overlay">
      <div className="contact-form">
        <div className="close-button-container">
          <button className="close-button" onClick={onClose} aria-label="Close">
            &times;
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <h2>Contact Me</h2>

          <div className="form-group">
            <label htmlFor="firstName">First Name</label>
            <input 
              type="text" 
              id="firstName" 
              name="firstName" 
              value={formData.firstName}
              onChange={handleChange}
              placeholder="Anita" 
              required 
            />
          </div>

          <div className="form-group">
            <label htmlFor="lastName">Last Name</label>
            <input 
              type="text" 
              id="lastName" 
              name="lastName" 
              value={formData.lastName}
              onChange={handleChange}
              placeholder="Bath" 
              required 
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input 
              type="email" 
              id="email" 
              name="email" 
              value={formData.email}
              onChange={handleChange}
              placeholder="anitabath@moestavern.com" 
              required 
            />
          </div>

          <div className="form-group">
            <label htmlFor="mobile">Mobile</label>
            <input 
              type="tel" 
              id="mobile" 
              name="mobile" 
              value={formData.mobile}
              onChange={handleChange}
              placeholder="212-555-1212" 
              pattern="\d{3}-\d{3}-\d{4}"
              required 
            />
          </div>

          <div className="form-group">
            <label htmlFor="topic">Topic</label>
            <textarea 
              id="topic" 
              name="topic" 
              value={formData.topic}
              onChange={handleChange}
              placeholder="I heard Bart was looking for me." 
              required
            ></textarea>
          </div>

          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>

          {submissionStatus === 'success' && <p className="success-message">Thank you! Your message has been sent.</p>}
          {submissionStatus === 'error' && <p className="error-message">{error}</p>}
        </form>
      </div>
      {showConfetti && (
        <ReactConfetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={300}
          recycle={false}
        />
      )}
    </div>
  );
};

export default ContactForm;
