import React, { useState, useEffect, useRef, useCallback } from "react";
import "./App.css";
import axios from "axios";
import TSCLogo from './TSC_ocho_logo.png';
import sendButtonIcon from './send_button.svg';
import hamburgerIcon from './hamburger-menu.svg';
import ReactConfetti from 'react-confetti';
import CookieConsent from './CookieConsent';
import { v4 as uuidv4 } from 'uuid';
import DOMPurify from 'dompurify';

// Import ContactForm and SubscribeForm
import ContactForm from './ContactForm';
import SubscribeForm from './SubscribeForm';

// Configure Axios to include credentials (cookies) with requests
axios.defaults.withCredentials = true;

// Add this before the App function
axios.interceptors.request.use(config => {
  const sessionId = localStorage.getItem('sessionId');
  if (sessionId) {
    config.headers['Session-ID'] = sessionId;
  }
  return config;
});

function App() {
  const [messages, setMessages] = useState([
    {
      role: "assistant",
      content: "What's up, Buttercup? Welcome to TSC! We used to be The Starr Conspiracy, but hey ... you know ... change and stuff.",
      timestamp: new Date().toISOString(),
    },
    {
      role: "assistant",
      content: "Before you ask, yes ... this is our real website. I'm chaDbot. I'm a lot like a chatbot, but lazier and less reliable 🥱 🤷.",
      timestamp: new Date().toISOString(),
    },
    {
      role: "assistant",
      content: "Click on the menu in the upper right-hand corner for a few conversation starters. Or just start typing and see what happens!",
      timestamp: new Date().toISOString(),
    },
  ]);
  const [input, setInput] = useState('');
  const messagesEndRef = useRef(null);
  const containerRef = useRef(null);
  const contactFormRef = useRef(null);
  const [showContactForm, setShowContactForm] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const messagesRef = useRef(null);
  const [isMenuActive, setIsMenuActive] = useState(false);
  const menuRef = useRef(null);
  const hamburgerRef = useRef(null);
  const inputRef = useRef(null);
  const [showSubscribeForm, setShowSubscribeForm] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [geolocationSent, setGeolocationSent] = useState(false);
  const [sessionId, setSessionId] = useState(() => {
    const savedSessionId = localStorage.getItem('sessionId');
    if (savedSessionId) {
      return savedSessionId;
    } else {
      const newSessionId = uuidv4();
      localStorage.setItem('sessionId', newSessionId);
      return newSessionId;
    }
  });

  const sendGeolocation = useCallback(async (latitude, longitude) => {
    try {
      const response = await axios.post('/api/geolocation', { latitude, longitude });
      setGeolocationSent(true);
      console.log('Server response:', response.data);
    } catch (error) {
      console.error('Error sending geolocation data:', error);
    }
  }, []);

  useEffect(() => {
    const requestGeolocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            sendGeolocation(latitude, longitude);
          },
          (error) => {
            console.error('Error obtaining geolocation:', error);
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
    };

    if (!geolocationSent) {
      requestGeolocation();
    }
  }, [geolocationSent, sendGeolocation]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const adjustContainerHeight = () => {
    if (containerRef.current) {
      const windowHeight = window.innerHeight;
      containerRef.current.style.height = `${windowHeight - 20}px`;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    adjustContainerHeight();
    window.addEventListener("resize", adjustContainerHeight);
    return () => window.removeEventListener("resize", adjustContainerHeight);
  }, []);

  useEffect(() => {
    if (showContactForm && contactFormRef.current) {
      contactFormRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [showContactForm]);

  useEffect(() => {
    const handleScroll = () => {
      if (messagesRef.current) {
        const messages = messagesRef.current.querySelectorAll('.user-message, .assistant-message');
        const logoStripe = document.querySelector('.logo-stripe');
        if (logoStripe) {
          const logoBottom = logoStripe.getBoundingClientRect().bottom;

          messages.forEach(message => {
            const messageTop = message.getBoundingClientRect().top;
            if (messageTop < logoBottom) {
              message.classList.add('scrolled');
            } else {
              message.classList.remove('scrolled');
            }
          });
        }
      }
    };

    const messagesContainer = messagesRef.current;
    if (messagesContainer) {
      messagesContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (messagesContainer) {
        messagesContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (showConfetti) {
      const timer = setTimeout(() => setShowConfetti(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [showConfetti]);

  useEffect(() => {
    const initSession = async () => {
      try {
        const response = await axios.get('/api/init-session');
        const newSessionId = response.data.sessionId;
        setSessionId(newSessionId);
        localStorage.setItem('sessionId', newSessionId);
      } catch (error) {
        console.error('Error initializing session:', error);
      }
    };
    initSession();
  }, []);

  const handleSend = async () => {
    if (!input.trim()) return;

    const userMessage = input.trim();

    setMessages((prevMessages) => [
      ...prevMessages,
      { role: 'user', content: userMessage },
    ]);

    setInput('');
    setIsTyping(true);

    try {
      const response = await axios.post('/api/chat', { query: userMessage });
      handleAssistantResponse(response.data);
    } catch (error) {
      console.error('Error during handleSend:', error);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          role: 'assistant',
          content: "I'm sorry, there was an error processing your request.",
        },
      ]);
    } finally {
      setIsTyping(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  function renderMessageContent(message) {
    if (message.type === "video") {
      return (
        <video 
          src={message.content} 
          controls 
          style={{ maxWidth: '100%', borderRadius: '8px' }}
        >
          Your browser does not support the video tag.
        </video>
      );
    } else if (message.role === 'assistant') {
      // Handle assistant messages with potential HTML content
      return (
        <div
          className="message-content"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message.content) }}
        />
      );
    } else {
      // Handle user messages or any other type
      return <div className="message-content">{message.content}</div>;
    }
  }

  const handleContactMe = (fromIntent = false) => {
    if (!fromIntent) {
      setMessages(prevMessages => [
        ...prevMessages,
        { role: "user", content: "Have someone contact me." }
      ]);
    }

    setIsTyping(true);

    setTimeout(() => {
      setMessages(prevMessages => [
        ...prevMessages,
        {
          role: "assistant",
          content: "I got you 💯. Let me take down your information and I'll have someone from TSC reach out.",
        },
      ]);
      setIsTyping(false);
      setShowContactForm(true);
    }, 1000);

    setIsMenuActive(false);
  };

  const handleWhoIsTSCClick = () => {
    setMessages(prevMessages => [...prevMessages, { role: "user", content: "Who is TSC?" }]);

    const assistantMessages = [
      "TSC is a full stack agency that is redefining marketing for tech companies—because the blocking and tackling era is over, and the road to hell 🗿🗿 is paved with performance optimizations.",
      "We break the script to create defining moments and definitive experiences that cement vendor preference. So jump in. It's time to burn playbooks, and we just bought a fireworks factory next to a fertilizer plant. 🔥🔥🔥🔥🔥",
      "They told me to say all that. Please help. 🆘 🆘 🆘 "
    ];

    setTimeout(() => {
      assistantMessages.forEach((message, index) => {
        setTimeout(() => {
          setIsTyping(true);
          setTimeout(() => {
            setIsTyping(false);
            setMessages(prevMessages => [...prevMessages, { role: "assistant", content: message }]);
          }, 750);
        }, index * 1500);
      });
    }, 500);
    setIsMenuActive(false);
  };

  const handleServicesClick = () => {
    setMessages(prevMessages => [...prevMessages, { role: "user", content: "What services does TSC provide?" }]);

    const assistantMessages = [
      "TSC is a full stack marketing agency for tech companies. We create brand, marketing, sales, and product experiences rooted in holistic customer experience.",
      "We are best known for strategy, experience design, research, AI enablement, brand, message, marketing, content, design, digital, and public relations.",
      "Since 1999, we've helped thousands of tech companies find their special slice of the market. 🍕🍕🍕"
    ];

    setTimeout(() => {
      assistantMessages.forEach((message, index) => {
        setTimeout(() => {
          setIsTyping(true);
          setTimeout(() => {
            setIsTyping(false);
            setMessages(prevMessages => [...prevMessages, { role: "assistant", content: message }]);
          }, 750);
        }, index * 1500);
      });
    }, 500);

    setIsMenuActive(false);
  };

  const handleSubscribeClick = () => {
    setMessages([...messages, { role: "user", content: "I would like to subscribe." }]);
    setIsTyping(true);
    setTimeout(() => {
      setMessages(prevMessages => [
        ...prevMessages,
        {
          role: "assistant",
          content: "Thanks for caring about TSC content! ❤️ Fill out this form (sorry) to set your content preferences.",
        },
      ]);
      setIsTyping(false);
      setShowSubscribeForm(true);
    }, 1000);
    setIsMenuActive(false);
  };

  const handleWorkClick = () => {
    setMessages(prevMessages => [
      ...prevMessages,
      { role: "user", content: "Show me that sizzle reel!" }
    ]);
    setIsTyping(true);
    setTimeout(() => {
      setMessages(prevMessages => [
        ...prevMessages,
        {
          role: "assistant",
          type: "video",
          content: "/videos/TSC_sizzle_reel.mp4"
        }
      ]);
      setIsTyping(false);
    }, 1000);
    setIsMenuActive(false);
  };

  const toggleMenu = () => {
    setIsMenuActive(!isMenuActive);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target) && !hamburgerRef.current.contains(event.target)) {
        setIsMenuActive(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get('/api/user');

        if (response.data && response.data.firstName) {
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              role: 'assistant',
              content: `So whatcha want to talk about, ${response.data.firstName}? Yeah ... I recognize you. Back for more?`,
              timestamp: new Date().toISOString(),
            },
          ]);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  const handleAssistantResponse = (assistantResponse) => {
    const { reply, intent } = assistantResponse;

    if (intent === 'Contact') {
      setMessages((prevMessages) => [
        ...prevMessages,
        { role: 'assistant', content: reply },
      ]);

      handleContactMe(true);
    } else {
      const responseText = reply || "I'm sorry, I didn't quite catch that.";
      setMessages((prevMessages) => [
        ...prevMessages,
        { role: 'assistant', content: responseText },
      ]);
    }
  };

  return (
    <div className="App">
      <div className="content-container">
        <div className="chat-container" ref={containerRef}>
          <img
            src={TSCLogo}
            alt="TSC Logo"
            className="logo"
            loading="lazy"
          />

          <div
            className="hamburger-menu"
            onClick={toggleMenu}
            ref={hamburgerRef}
            role="button"
            aria-label="Open menu"
            tabIndex={0}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                toggleMenu();
              }
            }}
          >
            <img
              src={hamburgerIcon}
              alt="Menu"
              loading="lazy"
            />
          </div>

          {isMenuActive && (
            <nav className="menu-content" ref={menuRef}>
              <div
                className="prompt-box clickable"
                onClick={handleWhoIsTSCClick}
                role="button"
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleWhoIsTSCClick();
                  }
                }}
              >
                <strong>Who is TSC?</strong>
                <p>Give me the short version.</p>
              </div>
              <div
                className="prompt-box clickable"
                onClick={handleServicesClick}
                role="button"
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleServicesClick();
                  }
                }}
              >
                <strong>Services</strong>
                <p>What does TSC do for a living?</p>
              </div>
              <div
                className="prompt-box clickable"
                onClick={handleContactMe}
                role="button"
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleContactMe();
                  }
                }}
              >
                <strong>Contact me</strong>
                <p>Have someone from TSC reach out.</p>
              </div>
              <div
                className="prompt-box clickable"
                onClick={handleSubscribeClick}
                role="button"
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSubscribeClick();
                  }
                }}
              >
                <strong>Subscribe</strong>
                <p>Sign up for TSC content.</p>
              </div>
              <div
                className="prompt-box clickable"
                onClick={() => {
                  window.open('https://www.linkedin.com/company/the-starr-conspiracy/?viewAsMember=true', '_blank');
                  setIsMenuActive(false);
                }}
                role="button"
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    window.open('https://www.linkedin.com/company/the-starr-conspiracy/?viewAsMember=true', '_blank');
                    setIsMenuActive(false);
                  }
                }}
              >
                <strong>LinkedIn</strong>
                <p>Take me to your socials.</p>
              </div>
              <div
                className="prompt-box clickable"
                onClick={handleWorkClick}
                role="button"
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleWorkClick();
                  }
                }}
              >
                <strong>Work</strong>
                <p>Show me that sizzle reel!</p>
              </div>
            </nav>
          )}

          <div className="messages" ref={messagesRef}>
            <CookieConsent />
            {messages.map((message, index) => (
              <div key={index} className={`message ${message.role === "user" ? "user-message" : "assistant-message"}`}>
                {renderMessageContent(message)}
              </div>
            ))}
            {showSubscribeForm && (
              <SubscribeForm
                onClose={() => setShowSubscribeForm(false)}
                onSubmit={(formData) => {
                  setShowSubscribeForm(false);
                  setShowConfetti(true);
                  setMessages((prevMessages) => [
                    ...prevMessages,
                    {
                      role: "assistant",
                      content: "🎉 You're now subscribed! Thank you!",
                    },
                  ]);
                }}
                sessionId={sessionId}
              />
            )}
            {isTyping && (
              <div className="typing-indicator">
                <span className="dot"></span>
                <span className="dot"></span>
                <span className="dot"></span>
              </div>
            )}
            {showContactForm && !isTyping && (
              <div className="assistant-message" ref={contactFormRef}>
                {renderMessageContent({ role: "assistant", content: "CONTACT_FORM" })}
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
          <div className="input-container">
            <textarea
              ref={inputRef}
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Start typing ..."
              className="message-input"
              rows="1"
              style={{ lineHeight: 'normal' }}
            />
            <button
              className="send-arrow"
              onClick={handleSend}
              aria-label="Send message"
            >
              <img src={sendButtonIcon} alt="Send" />
            </button>
          </div>
        </div>
      </div>
      {showConfetti && (
        <ReactConfetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={300}
          gravity={0.5}
          colors={['#ed0ad2', '#73f5ff', '#ff5910', '#e1ff00']}
          recycle={false}
        />
      )}
      <div className="form-container">
        {showContactForm && (
          <ContactForm
            onClose={() => setShowContactForm(false)}
            onSubmit={(formData) => {
              setShowContactForm(false);
              setShowConfetti(true);
            }}
            sessionId={sessionId}
          />
        )}
        {showSubscribeForm && (
          <SubscribeForm
            onClose={() => setShowSubscribeForm(false)}
            onSubmit={(formData) => {
              setShowSubscribeForm(false);
              setShowConfetti(true);
            }}
            sessionId={sessionId}
          />
        )}
      </div>
    </div>
  );
}

export default App;