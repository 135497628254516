import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './SubscribeForm.css';
import ReactConfetti from 'react-confetti';

const SubscribeForm = ({ onClose, onSubmit, sessionId }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    subscriptions: [],
    comments: ''
  });

  const [submissionStatus, setSubmissionStatus] = useState(null); // 'success' or 'error'
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (showConfetti) {
      const timer = setTimeout(() => setShowConfetti(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [showConfetti]);

  useEffect(() => {
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      subscriptions: [],
      comments: ''
    });
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData(prevData => ({
        ...prevData,
        subscriptions: checked
          ? [...prevData.subscriptions, value]
          : prevData.subscriptions.filter(item => item !== value)
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: name === 'mobile' ? formatPhoneNumber(value) : value
      }));
    }
  };

  const formatPhoneNumber = (value) => {
    const phoneNumber = value.replace(/\D/g, '');
    const match = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return value;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmissionStatus(null);

    try {
      const api = axios.create({
        withCredentials: true,
      });

      api.interceptors.request.use(config => {
        config.headers['Session-ID'] = sessionId;
        return config;
      });

      const response = await api.post('/api/subscribe', formData);
      console.log('Subscription form submission response:', response);
      setSubmissionStatus('success');
      setShowConfetti(true);
      onSubmit(formData);
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        subscriptions: [],
        comments: ''
      });
    } catch (error) {
      console.error('Error submitting subscription form:', error.response || error);
      setSubmissionStatus('error');
      const errorMessage = error.response?.data?.message || 'An error occurred. Please try again.';
      setErrorMessage(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="subscribe-form-overlay">
      <div className="subscribe-form">
        <div className="close-button-container">
          <button className="close-button" onClick={onClose} aria-label="Close">
            &times;
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <h2>Subscribe</h2>
          <div className="form-group">
            <label htmlFor="firstName">First Name</label>
            <input 
              type="text" 
              id="firstName" 
              name="firstName" 
              value={formData.firstName}
              onChange={handleChange}
              placeholder="Al" 
              required 
            />
          </div>

          <div className="form-group">
            <label htmlFor="lastName">Last Name</label>
            <input 
              type="text" 
              id="lastName" 
              name="lastName" 
              value={formData.lastName}
              onChange={handleChange}
              placeholder="Coholic" 
              required 
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input 
              type="email" 
              id="email" 
              name="email" 
              value={formData.email}
              onChange={handleChange}
              placeholder="AlCoholic@moestavern.com" 
              required 
            />
          </div>

          <div className="form-group">
            <label htmlFor="mobile">Mobile</label>
            <input 
              type="tel" 
              id="mobile" 
              name="mobile" 
              value={formData.mobile}
              onChange={handleChange}
              placeholder="212-555-1212" 
              pattern="\d{3}-\d{3}-\d{4}"
              required 
            />
          </div>

          <div className="form-group checkbox-group">
            <p>Pick your poison:</p>
            <label>
              <input 
                type="checkbox" 
                name="subscriptions" 
                value="Work Tech Weekly"
                checked={formData.subscriptions.includes('Work Tech Weekly')}
                onChange={handleChange}
              />
              Work Tech Weekly
            </label>
            <label>
              <input 
                type="checkbox" 
                name="subscriptions" 
                value="Work Leader Weekly"
                checked={formData.subscriptions.includes('Work Leader Weekly')}
                onChange={handleChange}
              />
              Work Leader Weekly
            </label>
            <label>
              <input 
                type="checkbox" 
                name="subscriptions" 
                value="Propaganda"
                checked={formData.subscriptions.includes('Propaganda')}
                onChange={handleChange}
              />
              Propaganda
            </label>
          </div>

          <div className="form-group">
            <label htmlFor="comments">Comments</label>
            <textarea 
              id="comments" 
              name="comments" 
              value={formData.comments}
              onChange={handleChange}
              placeholder="Could you have Bart deliver this?" 
            ></textarea>
          </div>

          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>

          {submissionStatus === 'success' && (
            <p className="success-message">Thank you! Your subscription has been received.</p>
          )}
          {submissionStatus === 'error' && (
            <p className="error-message">An error occurred. Please try again.</p>
          )}
          {errorMessage && (
            <p className="error-message">{errorMessage}</p>
          )}
        </form>
      </div>
      {showConfetti && (
        <ReactConfetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={300}
          recycle={false}
        />
      )}
    </div>
  );
};

export default SubscribeForm;
